import { render, staticRenderFns } from "./LabelsTab.vue?vue&type=template&id=6daef08c&scoped=true&"
import script from "./LabelsTab.vue?vue&type=script&lang=js&"
export * from "./LabelsTab.vue?vue&type=script&lang=js&"
import style0 from "./LabelsTab.vue?vue&type=style&index=0&id=6daef08c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6daef08c",
  null
  
)

export default component.exports